/* Footer Styling */
.footer {
    background-color: var(--primary-color); /* Deep Green for footer background */
    color: white; /* White text for contrast */
    padding: 40px 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Add shadow for a lifted effect */
    text-align: center; /* Center text for a clean look */
}

/* Footer Container */
.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

/* Footer Section Styling */
.footer-section {
    flex: 1;
    min-width: 250px;
    margin: 20px;
    transition: transform 0.3s;
}

    .footer-section:hover {
        transform: translateY(-5px); /* Lift effect on hover */
    }

    .footer-section h3 {
        font-size: 1.5rem;
        margin-bottom: 15px;
        color: white; /* White text for headings */
        border-bottom: 2px solid var(--accent-color); /* Soft Green underline */
        padding-bottom: 5px;
    }

    .footer-section p,
    .footer-section li {
        font-size: 1rem;
        margin-bottom: 10px;
        line-height: 1.5;
        color: #e0f7e9; /* Light text color for readability */
    }

    .footer-section a {
        color: #e0f7e9; /* Light text color for links */
        text-decoration: none;
        transition: color 0.3s, transform 0.2s;
    }

        .footer-section a:hover {
            color: white; /* White color on hover */
            transform: scale(1.05); /* Slight zoom on hover */
        }

    /* Unordered List Reset */
    .footer-section ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

/* Footer Bottom Section */
.footer-bottom {
    text-align: center;
    margin-top: 30px;
    border-top: 1px solid #ccc;
    padding-top: 20px;
    font-size: 0.9rem;
    color: #e0f7e9; /* Light text color for readability */
}

/* Social Links */
.social-link {
    margin-right: 15px;
    font-size: 1.5rem;
    color: #e0f7e9; /* Light text color for social icons */
    transition: color 0.3s, transform 0.3s;
}

    .social-link:hover {
        color: white; /* White color on hover */
        transform: translateY(-3px);
    }

/* Responsive Design */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        margin: 20px 0;
        text-align: center;
    }

    .social-link {
        display: inline-block;
        margin: 5px;
    }
}

/* Additional Styling for Very Small Screens */
@media (max-width: 480px) {
    .footer {
        padding: 30px 15px; /* Adjust padding for smaller screens */
    }

    .footer-section h3 {
        font-size: 1.3rem;
    }

    .footer-section p,
    .footer-section li {
        font-size: 0.9rem;
    }
}
