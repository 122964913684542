/* Reset Default Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Earthy Green Theme Variables */
:root {
    --primary-color: #0C3C01; /* Deep Green */
    --accent-color: #5B6D49; /* Soft Muted Green */
    --background-color: #F1F2ED; /* Light Off-White */
    --text-color: #2E2D1D; /* Dark Brown Text */
    --button-bg-color: #0C3C01; /* Deep Green for Buttons */
    --button-hover-color: #5B6D49; /* Soft Muted Green on Hover */
    --header-bg-color: #0C3C01; /* Deep Green for Header */
    --footer-bg-color: #0C3C01; /* Deep Green for Footer */
}

/* Global Styles */
html, body {
    width: 100%;
    height: 100%;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
    overflow-x: hidden;
    line-height: 1.6; /* Improved readability */
}

/* App Container */
.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Main Content Area */
.main-content {
    flex: 1;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 12px; /* Updated border radius for a smoother look */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Softer shadow */
    overflow: hidden;
    transition: box-shadow 0.3s; /* Smooth transition for hover effect */
}

.main-content:hover {
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
}

/* Header and Footer Styles */
header, footer {
    width: 100%;
    background-color: var(--header-bg-color);
    color: white;
    text-align: center;
    padding: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
}

/* Logo Styling */
.logo-image {
    height: 60px;
    width: auto;
    object-fit: contain;
    transition: transform 0.3s, filter 0.3s;
}

.logo-link:hover .logo-image {
    transform: scale(1.1);
    filter: brightness(1.2); /* Brighten logo on hover */
}

/* Navigation Links */
.navigation {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.nav-link {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    transition: color 0.3s, transform 0.2s;
    padding: 8px 12px;
    border-radius: 4px;
}

.nav-link:hover {
    color: var(--accent-color);
    background-color: #5B6D49;
    transform: translateY(-2px);
}

/* Buttons */
button {
    background-color: var(--button-bg-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
}

button:hover {
    background-color: var(--button-hover-color);
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Button shadow on hover */
}

/* Product Items and Cart Items */
.product-item, .cart-item {
    background-color: #ffffff;
    color: var(--text-color);
    padding: 15px;
    margin: 10px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
    width: calc(100% - 20px);
}

.product-item:hover, .cart-item:hover {
    transform: translateY(-5px);
    background-color: var(--background-color);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
}

/* Ensure Images are Responsive */
img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    transition: transform 0.3s, filter 0.3s;
}

img:hover {
    transform: scale(1.05);
    filter: brightness(1.1); /* Brighten image on hover */
}

/* Cart Total Section */
.cart-total {
    text-align: right;
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    border-top: 1px solid #ddd;
    padding-top: 10px;
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
    .main-content {
        padding: 10px;
        flex-direction: column;
    }

    .navigation {
        flex-direction: column;
        gap: 10px;
    }

    .product-item, .cart-item {
        margin-bottom: 15px;
        width: 100%;
    }

    .logo-image {
        height: 50px;
    }
}

@media (max-width: 480px) {
    .main-content {
        padding: 10px;
    }

    .navigation {
        flex-direction: column;
        gap: 8px;
    }

    .product-item, .cart-item {
        width: 100%;
        padding: 10px;
    }

    .logo-image {
        height: 45px;
    }

    button {
        width: 100%;
        padding: 8px;
    }
}
