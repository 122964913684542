/* src/components/Cart.css */
.cart {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.cart-item {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: #f9f9f9;
    margin-bottom: 15px;
}

.cart-item-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 15px;
}

.item-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.quantity-control {
    display: flex;
    align-items: center;
    gap: 5px;
}

    .quantity-control input {
        width: 50px;
        padding: 4px;
        text-align: center;
    }

.remove-button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

    .remove-button:hover {
        background-color: #e60000;
    }

.cart-total {
    text-align: right;
    font-size: 1.2rem;
    font-weight: bold;
}
