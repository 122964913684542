/* Product Detail Container */
.product-detail {
    display: flex;
    gap: 30px;
    margin: 40px;
    align-items: flex-start;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

/* Left Section (Image and Selections) */
.product-detail-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 400px;
}

/* Right Section (Details) */
.product-detail-right {
    flex: 2;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Deeper shadow for a richer look */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

    .product-detail-right:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Lift effect on hover */
    }

/* Image Styling */
.product-image {
    width: 100%;
    max-width: 350px;
    height: auto;
    object-fit: contain;
    border-radius: 8px;
    transition: transform 0.3s; /* Smooth zoom effect on hover */
}

    .product-image:hover {
        transform: scale(1.05); /* Slight zoom on hover */
    }

/* Size and Quantity Selection */
.size-selection, .quantity-selection {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#size-select, #quantity-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s;
}

    #size-select:focus, #quantity-input:focus {
        border-color: #4CAF50; /* Highlight border on focus */
    }

/* Price Styling */
.product-price {
    font-size: 1.4rem; /* Slightly larger for emphasis */
    font-weight: bold;
    color: #333; /* Darker color for contrast */
    margin: 15px 0;
}

/* Add to Cart Button */
.add-to-cart-button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.2s;
}

    .add-to-cart-button:disabled {
        background-color: #ddd;
        cursor: not-allowed;
    }

    .add-to-cart-button:hover:not(:disabled) {
        background-color: #45a049;
        transform: translateY(-2px); /* Lift effect on hover */
    }

/* Responsive Design Adjustments */
@media (max-width: 768px) {
    .product-detail {
        flex-direction: column;
        gap: 20px;
        margin: 20px;
    }

    .product-detail-right {
        padding: 15px;
    }

    .product-price {
        font-size: 1.2rem; /* Adjust font size for smaller screens */
    }

    .add-to-cart-button {
        padding: 10px 20px;
    }
}

@media (max-width: 480px) {
    .product-detail-left {
        max-width: 100%; /* Full width for smaller screens */
    }

    .product-price {
        font-size: 1.1rem;
    }

    .add-to-cart-button {
        width: 100%; /* Full-width button for easier access */
    }
}
