/* Header Styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    background-color: var(--primary-color); /* Deep Green for header background */
    color: white;
    width: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
    z-index: 1000; /* Ensure the header stays above other content */
}

/* Logo Container */
.logo-container {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the logo horizontally */
}

.logo-link {
    text-decoration: none; /* Remove any link decoration */
}

.logo-image {
    height: 80px; /* Increase height for better visibility */
    width: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the logo scales nicely */
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3)); /* Subtle shadow effect */
    transition: transform 0.3s, filter 0.3s; /* Smooth hover effect */
}

.logo-link:hover .logo-image {
    transform: scale(1.05); /* Subtle zoom effect on hover */
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.5)); /* Enhance shadow on hover */
}

/* Navigation Styling */
.navigation {
    display: flex;
    gap: 20px;
    align-items: center; /* Center navigation items vertically */
}

.nav-link {
    text-decoration: none;
    color: white; /* White text for links */
    font-size: 1rem;
    font-weight: bold;
    transition: color 0.3s ease, transform 0.2s; /* Smooth color and transform effect */
    padding: 8px 12px;
    border-radius: 4px;
}

.nav-link:hover {
    color: var(--accent-color); /* Soft Muted Green on hover */
    background-color: #5B6D49;
    transform: translateY(-2px); /* Lift effect on hover */
}

/* Mobile Responsive Design */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        padding: 10px 20px; /* Adjust padding for smaller screens */
    }

    .navigation {
        flex-direction: column;
        gap: 15px; /* Increase gap for better spacing */
    }

    .nav-link {
        padding: 10px 15px; /* Increase padding for easier tapping */
    }
}

/* Further Adjustments for Very Small Screens */
@media (max-width: 480px) {
    .logo-image {
        height: 70px; /* Reduce logo size slightly for very small screens */
    }

    .nav-link {
        font-size: 0.9rem; /* Adjust font size for readability */
        padding: 8px 10px; /* Adjust padding to fit smaller screens */
    }

    .navigation {
        gap: 10px; /* Reduce gap to accommodate smaller screens */
    }
}

