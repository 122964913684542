/* Enhanced Hero Section Styling */
.hero-section {
    position: relative;
    width: 100%;
    height: 500px; /* Adjust height as needed */
    overflow: hidden;
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Improved Slide Animation */
.hero-slide {
    display: none;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

    .hero-slide.active {
        display: block;
        opacity: 1;
    }

/* Hero Image Styling */
.hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.8); /* Darken image for better text contrast */
    transition: filter 0.3s;
}

    .hero-image:hover {
        filter: brightness(1); /* Brighten on hover */
    }

/* Navigation Arrows */
.left-arrow,
.right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 2rem;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s, transform 0.2s;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
}

.left-arrow {
    left: 20px;
}

.right-arrow {
    right: 20px;
}

    .left-arrow:hover,
    .right-arrow:hover {
        background-color: rgba(0, 0, 0, 0.8);
        transform: translateY(-50%) scale(1.1);
    }

/* Caption Styling */
.hero-caption {
    position: absolute;
    bottom: 50px;
    left: 50px;
    background-color: rgba(255, 255, 255, 0.8);
    color: #000;
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: 6px; /* Slightly rounded corners for a modern look */
    backdrop-filter: blur(6px); /* More prominent blur effect */
    max-width: 70%; /* Restrict width to ensure readability */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Shadow for depth */
}

    .hero-caption h2 {
        font-size: 2.5rem; /* Increase font size for better emphasis */
        margin: 0;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3); /* Text shadow for better readability */
    }

/* Responsive Design Enhancements */
@media (max-width: 768px) {
    .hero-section {
        height: 400px; /* Adjust height for smaller screens */
    }

    .hero-caption {
        bottom: 30px;
        left: 20px;
        padding: var(--spacing-xs) var(--spacing-sm);
        max-width: 90%;
    }

        .hero-caption h2 {
            font-size: 1.8rem; /* Adjust font size for smaller screens */
        }

    .left-arrow,
    .right-arrow {
        font-size: 1.5rem;
        padding: 8px;
    }
}

@media (max-width: 480px) {
    .hero-section {
        height: 300px; /* Further adjust for very small screens */
    }

    .hero-caption {
        bottom: 20px;
        left: 10px;
        padding: var(--spacing-xxs) var(--spacing-xs);
    }

        .hero-caption h2 {
            font-size: 1.5rem; /* Further adjustment for readability */
        }

    .left-arrow,
    .right-arrow {
        font-size: 1.2rem;
        padding: 6px;
    }
}
