/* Reset Default Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Earthy Green Theme Variables */
:root {
    --primary-color: #0C3C01; /* Deep Green */
    --accent-color: #5B6D49; /* Soft Muted Green */
    --background-color: #F1F2ED; /* Light Off-White */
    --text-color: #2E2D1D; /* Dark Brown Text */
    --highlight-color: #A2AC82; /* Highlight color for hover effects */
}

/* Global Styles */
html, body {
    width: 100%;
    height: 100%;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
    overflow-x: hidden;
}

/* Home Container */
.home {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    margin: 0 auto;
    overflow: hidden;
    background: linear-gradient(135deg, #A2AC82, #0C3C01, #5B6D49); /* Updated gradient */
    color: white;
}

/* Home Content */
.home-content {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Softer shadow */
    backdrop-filter: blur(5px);
    animation: fadeIn 0.8s ease-out;
    transition: all 0.3s ease;
}

.home-content:hover {
    box-shadow: 0 12px 35px rgba(0, 0, 0, 0.2); /* Elevated shadow on hover */
    outline: 2px solid transparent;
    outline-offset: -2px;
    border-image: linear-gradient(45deg, #A2AC82, #0C3C01) 1; /* Gradient outline */
}

/* Inquiry Form Styling */
.inquiry-form {
    background-color: var(--light-color); /* Light background to match theme */
    border: 1px solid var(--secondary-color); /* Border color matching the theme */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    color: var(--text-color);
    max-width: 800px;
    margin: 40px auto; /* Center the form with spacing */
    transition: background-color 0.3s, box-shadow 0.3s;
}

.inquiry-title {
    font-size: 1.8rem;
    color: black; /* Set the heading color to black */
    text-align: center;
    margin-bottom: 20px;
}

.inquiry-form iframe {
    width: 100%; /* Full width for responsiveness */
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

/* Decorative Divider */
.divider {
    width: 80px;
    height: 4px;
    background: linear-gradient(90deg, #A2AC82, #0C3C01);
    margin: 20px auto;
    border-radius: 2px;
}

/* Product Title Styling */
.home-product-title {
    margin-top: 30px;
    font-size: 2rem;
    font-weight: bold;
    color: var(--text-color);
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    transition: color 0.3s ease;
}

.home-product-title:hover {
    color: var(--accent-color);
}

/* Image Styling */
img {
    width: 100%; /* Ensure images fully fill their container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Cover the container without distortion */
    border-radius: 8px;
    transition: transform 0.3s, filter 0.3s;
}

img:hover {
    transform: scale(1.05);
    filter: brightness(1.1); /* Brighten image on hover */
}

/* Floating Card Effect */
.floating-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s, box-shadow 0.3s;
}

.floating-card:hover {
    transform: translateY(-12px); /* Increased float effect */
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2); /* Deeper shadow */
}

/* Call-to-Action Section */
.cta-section {
    background-color: var(--accent-color);
    color: white;
    padding: 30px;
    text-align: center;
    border-radius: 10px;
    margin-top: 20px;
}

.cta-section h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.cta-section button {
    background-color: var(--primary-color);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.cta-section button:hover {
    background-color: var(--highlight-color);
    transform: scale(1.05);
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .home-content {
        width: 95%;
        padding: 10px;
    }

    .home-product-title {
        font-size: 1.8rem;
    }

    .floating-card {
        width: 100%;
        margin-bottom: 15px;
    }
}

@media (max-width: 480px) {
    .home-content {
        width: 90%;
        padding: 5px;
    }

    .home-product-title {
        font-size: 1.6rem;
    }

    .floating-card {
        width: 100%;
        margin-bottom: 10px;
    }

    .cta-section {
        padding: 20px;
    }
}
/* Price List Button Styling */
.price-list-link {
    display: inline-block;
    background-color: var(--accent-color); /* Use the accent color for the button */
    color: white; /* White text for good contrast */
    font-size: 1.1rem; /* Slightly larger font size */
    font-weight: bold;
    text-decoration: none; /* Remove default link underline */
    padding: 12px 24px; /* Add padding for a button-like look */
    border-radius: 8px; /* Rounded corners */
    margin: 20px 0; /* Add some margin for spacing */
    transition: background-color 0.3s ease, transform 0.2s; /* Smooth transition for hover effect */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
    text-align: center; /* Center the text */
}

.price-list-link:hover {
    background-color: #5B6D49; /* Darker shade on hover */
    transform: translateY(-3px); /* Slight lift effect on hover */
}
