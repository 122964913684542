.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto; /* Center the product list */
}

.product-item {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
}

.product-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.product-link {
    text-decoration: none;
    color: inherit;
}

.product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-bottom: 1px solid #e0e0e0;
    transition: opacity 0.3s;
}

.product-image:hover {
    opacity: 0.85;
}

h3 {
    font-size: 1.2rem;
    margin: 15px 0;
    color: #333;
}

.view-details-button {
    display: inline-block;
    background-color: #28a745;
    color: #fff;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.2s;
    font-weight: bold;
}

.view-details-button:hover {
    background-color: #218838;
    transform: translateY(-2px);
}
